import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  UserCredential,
  GoogleAuthProvider,
  connectAuthEmulator as connectAuthEmulatorOrg,
  Auth,
} from 'firebase/auth';
import { auth as firebaseAuth } from 'firebaseui';

import { configureFirebase } from '@/config/config';
import { urls } from '@/urls';

const options = configureFirebase();
const app = initializeApp(options);

export const firebaseUiConfig: firebaseAuth.Config = {
  signInFlow: 'redirect',
  signInSuccessUrl: urls.record.coffees,
  signInOptions: [GoogleAuthProvider.PROVIDER_ID],
};

export const emailAndPasswordLogin = async (
  email: string,
  password: string
): Promise<UserCredential> => {
  const auth = getAuth(app);
  connectAuthEmulator(auth);

  const userCredential = await signInWithEmailAndPassword(auth, email, password);

  return userCredential;
};

export const connectAuthEmulator = (auth: Auth): void => {
  if (process.env.REACT_APP_ENV !== 'local') return;
  try {
    connectAuthEmulatorOrg(auth, 'http://localhost:9099', {
      disableWarnings: true,
    });
    console.info('Firebase Auth: emulated');
  } catch (e) {
    console.error('Firebase Auth: not emulated', { error: e });
  }
};
