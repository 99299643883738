import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { Box, Button, ChakraProvider, Spinner } from '@chakra-ui/react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { configureEndpoint } from '@/config/config';
import { AuthProvider } from '@/contexts/AuthProvider';
import { CustomTheme } from '@/CustomTheme';
import { Fonts } from '@/Fonts';

import { MetaProvider } from './MetaProvider';

const ErrorFallback = () => {
  return (
    <div>
      <h2>Ooops, something went wrong :( </h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </div>
  );
};

const client = new ApolloClient({
  uri: configureEndpoint({
    protocol: window.location.protocol,
  }).graphql,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          boughtCoffeebeans: relayStylePagination(),
          brewedCoffees: relayStylePagination(),
          visitedCafes: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
});

type Props = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: Props) => {
  return (
    <React.Suspense fallback={<Fallback />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <RecoilRoot>
          <HelmetProvider>
            <MetaProvider>
              <ChakraProvider theme={CustomTheme}>
                <AuthProvider>
                  <ApolloProvider client={client}>
                    <BrowserRouter>
                      <Fonts />
                      {children}
                    </BrowserRouter>
                  </ApolloProvider>
                </AuthProvider>
              </ChakraProvider>
            </MetaProvider>
          </HelmetProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </React.Suspense>
  );
};

const Fallback = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
      <Spinner
        marginTop={36}
        thickness="4px"
        speed="1.5s"
        emptyColor="gray.200"
        color="brand.primary"
        size="xl"
      />
    </Box>
  );
};
