import { Global } from '@emotion/react';
import { FC } from 'react';

export const Fonts: FC = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: toppan-bunkyu-midashi-go-std, sans-serif;
        font-style: normal;
        font-weight: 900;
      }
      `}
  />
);
