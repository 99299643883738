import Axios from 'axios';

import { configureEndpoint } from '@/config/config';

export const axios = Axios.create({
  headers: {
    'Access-Control-Allow-Origin': 'http://localhost:8080',
  },
  baseURL: configureEndpoint({
    protocol: window.location.protocol,
  }).base,
  timeout: 3600,
});
