import './libs/i18n/config';
import { FC } from 'react';

import { AppProvider } from '@/providers/app';
import { AppRoutes, InDevAppRoutes } from '@/routes';

import { isProduction } from './config/config';

export const App: FC = () => (
  // TODO: 公開したらInDevAppRoutesは削除する
  <AppProvider>{isProduction ? <InDevAppRoutes /> : <AppRoutes />}</AppProvider>
);
