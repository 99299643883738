import { Mapping } from './types';

export const ja: Mapping = {
  JAPANESE: '日本語',
  ENGLISH: '英語',
  LOGIN: 'ログイン',
  BREWED_DATE: '淹れた日',
  BREW_TYPE: '淹れ方',
  BOUGHT_DATE: '購入日',
  COFFEEBEANS: 'コーヒー豆',
  BOUGHT_SHOP: '購入したお店',
  ROAST_TYPE: '焙煎度',
  PRICE: '価格',
  YEN: '円',
  PURCHASED_AMOUNT: '購入量',
  GRAM: 'g',
  TASTE_RATE: '好み',
  MEMO: 'メモ',
  PLEASE_SELECT: '選択してください',
  SAVE: '入力内容を記録する',
  COPY: 'コピーする',
  JP_LIGHT: '浅煎り',
  JP_MEDIUM_LIGHT: '中浅煎り',
  JP_MEDIUM: '中煎り',
  JP_MEDIUM_DARK: '中深煎り',
  JP_DARK: '深煎り',
  JP_EXTRA_DARK: '極深煎り',
  EN_LIGHT: 'Light Roast（ライトロースト）',
  EN_CINNAMON: 'Cinnamon Roast（シナモンロースト）',
  EN_MEDIUM: 'Medium Roast（ミディアムロースト）',
  EN_HIGH: 'High Roast（ハイロースト）',
  EN_CITY: 'City Roast（シティロースト）',
  EN_FULL_CITY: 'FULL City Roast（フルシティロースト）',
  EN_FRENCH: 'French Roast（フレンチロースト）',
  EN_ITALIAN: 'Italian Roast（イタリアンロースト）',
  X_REQUIRED: '{{x}}を入力してください',
  X_MAX: '{{x}}以内で入力してください',
  X_PLEASE_SELECT: '{{x}}を選択してください',
  ZOD_AT_LEAST: 'at least',
  ZOD_MORE_THAN: 'more than',
  ZOD_OVER: 'over',
  ZOD_OR_EQUAL_TO: 'or equal to',
  ZOD_AT_MOST: 'at most',
  ZOD_LESS_THAN: 'less than',
  ZOD_UNDER: 'under',
  ZOD_REQUIRED: 'Required',
  ZOD_NOT_MATCH_EXPECTED_RECEIVED: 'Expected {{x}}, received {{y}}',
  ZOD_INVALID_LITERAL: 'Invalid literal value, expected {{x}}',
  ZOD_UNRECOGNIZED_KEYS: 'Unrecognized key(s) in object: {{x}}',
  ZOD_INVALID_UNION_DISCRIMINATOR: 'Invalid discriminator value. Expected {{x}}',
  ZOD_INVALID_ENUM_VALUE: "Invalid enum value. Expected {{x}}, received '{{y}}'",
  ZOD_INVALID_ARGUMENTS: 'Invalid function arguments',
  ZOD_INVALID_RETURN_TYPE: 'Invalid function return type',
  ZOD_INVALID_DATE: 'Invalid date',
  ZOD_INVALID_STRING_STARTS_WITH: 'Invalid input: must start with "{{x}}"',
  ZOD_INVALID_STRING_ENDS_WITH: 'Invalid input: must end with "{{x}}"',
  ZOD_INVALID_REGEX: 'Invalid {{x}}',
  ZOD_INVALID: 'Invalid',
  ZOD_INVALID_INPUT: 'Invalid input',
  ZOD_TOO_SMALL_ARRAY: 'Array must contain {{x}} {{y}} element(s)',
  ZOD_TOO_SMALL_STRING: 'String must contain {{x}} {{y}} character(s)',
  ZOD_TOO_SMALL_NUMBER: 'Number must be greater than {{x}}{{y}}',
  ZOD_TOO_SMALL_DATE: 'Date must be greater than {{x}}{{y}}',
  ZOD_TOO_BIG_ARRAY: 'Array must contain {{x}} {{y}} element(s)',
  ZOD_TOO_BIG_STRING: 'String must contain {{x}} {{y}} character(s)',
  ZOD_TOO_BIG_NUMBER: 'Number must be less than {{x}}{{y}}',
  ZOD_TOO_BIG_DATE: 'Date must be smaller than {{x}}{{y}}',
  ZOD_INVALID_INTERSECTION_TYPES: 'Intersection results could not be merged',
  ZOD_NOT_MULTIPLE_OF_X: 'Number must be a multiple of {{x}}',
};
