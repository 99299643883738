import { Navigate } from 'react-router';

import { urls } from '@/urls';
import { lazyImport } from '@/utils/lazyImport';

const { RecordRoutes } = lazyImport(() => import('@/features/record'), 'RecordRoutes');

export const protectedRoutes = [
  {
    path: '/record/*',
    element: <RecordRoutes />,
  },
  { path: '*', element: <Navigate to={urls.record.coffees} /> },
];
