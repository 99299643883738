export const appName = 'COFFEE HOUSE - コーヒー好きが集まるオンラインサービス';

export const env = process.env.REACT_APP_ENV;
export const envSettings = {
  local: 'local',
  development: 'development',
  staging: 'staging',
  production: 'production',
} as const;

export const isProduction = env === envSettings.production;

export type DomainSetting = {
  appDomain: string;
};

const domainSettings: {
  [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: DomainSetting;
} = {
  local: {
    appDomain: 'localhost',
  },
  development: {
    appDomain: 'dev.coffeehouse.dev',
  },
  staging: {
    appDomain: 'stg.coffeehouse.dev',
  },
  production: {
    appDomain: 'coffeehouse.dev',
  },
};

export const configureDomain = (): DomainSetting => domainSettings[process.env.REACT_APP_ENV];

export type ApiSetting = {
  host: string;
};

const apiSettings: {
  [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: ApiSetting;
} = {
  local: {
    host: 'localhost:8080',
  },
  development: {
    host: 'api-development-dot-coffeehouse-dev.an.r.appspot.com',
  },
  staging: {
    host: 'api-staging-dot-coffeehouse-dev.an.r.appspot.com',
  },
  production: {
    host: 'api-production-dot-coffeehouse-prod.an.r.appspot.com',
  },
};

export type ApiEndpoint = {
  base: string;
  graphql: string;
};

export const configureEndpoint = ({ ...props }: { protocol: string }): ApiEndpoint => {
  const setting = apiSettings[process.env.REACT_APP_ENV];
  const { protocol, host } = { ...props, ...setting };

  const base = `${protocol}//${host}`;
  const graphql = `${protocol}//${host}/query`;

  return { base, graphql };
};

export type Firebase = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucketId: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
};

const firebaseSettings: {
  [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: Firebase;
} = {
  local: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: '',
    projectId: '',
    storageBucketId: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  development: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: 'coffee-house-dev.firebaseapp.com',
    projectId: 'coffee-house-dev',
    storageBucketId: 'coffee-house-dev.appspot.com',
    messagingSenderId: '1084377033112',
    appId: '1:1084377033112:web:26cb2b6521c27ef228a3ed',
    measurementId: 'G-428XKSNJDQ',
  },
  staging: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: '',
    projectId: '',
    storageBucketId: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  production: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: '',
    projectId: '',
    storageBucketId: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
};

// トラッキングコードの設定
export type Tracking = {
  googleTagManagerCode: string;
};

const trackingSettings: { [env in NodeJS.ProcessEnv['REACT_APP_ENV']]: Tracking } = {
  local: {
    googleTagManagerCode: '',
  },
  development: {
    googleTagManagerCode: 'GTM-MFWBV4V',
  },
  staging: {
    googleTagManagerCode: 'GTM-MP68DC8',
  },
  production: {
    googleTagManagerCode: 'GTM-NN67VLG',
  },
};

export const configureTracking = (): Tracking => trackingSettings[process.env.REACT_APP_ENV];

export const configureFirebase = (): Firebase => firebaseSettings[process.env.REACT_APP_ENV];
