import { Box, Spinner } from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { AuthUser, getUser } from '@/features/auth';
import { connectAuthEmulator } from '@/libs/firebase';

export type AuthenticateProps = {
  user: AuthUser;
  isLoggedIn: boolean;
  loading: boolean;
};

export const AuthenticateContext = createContext<AuthenticateProps>({
  user: {} as AuthUser,
  isLoggedIn: false,
  loading: false,
});
export const SetAuthenticateContext = createContext<Dispatch<SetStateAction<AuthenticateProps>>>(
  () => {
    throw new Error('Should use AuthContext.Provider');
  }
);

export const useAuth = (): AuthenticateProps => useContext(AuthenticateContext);
export const useSetAuth = (): Dispatch<SetStateAction<AuthenticateProps>> =>
  useContext(SetAuthenticateContext);

export type AuthProviderProps = {
  children: ReactNode;
};
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authenticate, setAuthenticate] = useState<AuthenticateProps>({
    user: {} as AuthUser,
    isLoggedIn: false,
    loading: false,
  });
  const auth = getAuth();
  connectAuthEmulator(auth);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const data = await getUser(user.uid);
        if (data) {
          setAuthenticate({ user: data, isLoggedIn: true, loading: true });
        } else {
          setAuthenticate({ user: {} as AuthUser, isLoggedIn: false, loading: true });
        }
      } else {
        setAuthenticate({ user: {} as AuthUser, isLoggedIn: false, loading: true });
      }
      unsubscribe();
    });
  }, [auth]);

  if (authenticate.loading) {
    return (
      <SetAuthenticateContext.Provider value={setAuthenticate}>
        <AuthenticateContext.Provider value={authenticate}>{children}</AuthenticateContext.Provider>
      </SetAuthenticateContext.Provider>
    );
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Spinner
        marginTop={36}
        thickness="4px"
        speed="1.5s"
        emptyColor="gray.200"
        color="brand.primary"
        size="xl"
      />
    </Box>
  );
};
