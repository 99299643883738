export const generateCopyParam = (url: string, id: string): string => `${url}?copyId=${id}`;

export const urls = {
  root: '/',
  login: '/login',
  record: {
    coffees: '/record/coffee',
    coffeeDetail: '/record/coffee/:id',
    coffeeNew: '/record/coffee/new',
    coffeeEdit: '/record/coffee/edit/:id',
    coffeebeans: '/record/coffeebeans',
    coffeebeanDetail: '/record/coffeebeans/:id',
    coffeebeanNew: '/record/coffeebeans/new',
    coffeebeanEdit: '/record/coffeebeans/edit/:id',
    cafes: '/record/cafe',
    cafeDetail: '/record/cafe/:id',
    cafeNew: '/record/cafe/new',
    cafeEdit: '/record/cafe/edit/:id',
  },
};
