import { Helmet } from 'react-helmet-async';

import { env, envSettings } from '@/config/config';

export type MetaProviderProps = {
  children: React.ReactNode;
};

export const MetaProvider = ({ children }: MetaProviderProps) => (
  <>
    <Helmet>{env != envSettings.production && <meta name="robots" content="noindex" />}</Helmet>
    {children}
  </>
);
