import { theme, extendTheme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

export const CustomTheme = extendTheme({
  styles: {
    global: {
      body: {
        color: 'brand.primary',
        lineHeight: '1.75',
      },
    },
  },
  colors: {
    brand: {
      primary: '#270d06',
      secondary: '#f8b500',
      accent: '#9c2d40',
      basePrimary: '#fff8e1',
      baseSecondary: '#fff1e1',
      white: '#fff',
      borderColor: 'rgba(39, 13, 6, 0.15)',
      primaryAlpha: 'rgba(39, 13, 6, 0.85)',
    },
    twitter: '#55acee',
    alert: '#d81638',
  },
  fonts: {
    heading: 'toppan-bunkyu-midashi-go-std, sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
      },
      variants: {
        secondary: (props: StyleFunctionProps) => ({
          ...theme.components.Button.variants?.outline(props),
          bg: 'brand.secondary',
          color: 'brand.primary',
          _hover: {
            bg: 'brand.secondary',
          },
          _active: {
            bg: '#ebb115',
          },
        }),
        twitter: (props: StyleFunctionProps) => ({
          ...theme.components.Button.variants?.outline(props),
          borderColor: 'twitter',
          bg: 'white',
          boxShadow: 'md',
          color: 'twitter',
          fontFamily: 'toppan-bunkyu-midashi-go-std, sans-serif',
          fontStyle: 'normal',
          fontWeight: '900',
          _hover: {
            textDecoration: 'none',
          },
        }),
      },
    },
    GridItem: {
      variants: {
        primary: (props: StyleFunctionProps) => ({
          ...theme.components.Button.variants?.outline(props),
          bg: 'brand.primary',
          color: 'white',
          textAlign: 'center',
        }),
      },
    },
    Tabs: {
      baseStyle: {},
      variants: {
        headerNav: (props: StyleFunctionProps) => ({
          ...theme.components.Tabs.variants?.line(props).tab,
          tab: {
            paddingTop: 3,
            paddingBottom: 3,
            paddingRight: 1,
            paddingLeft: 1,
            boxShadow: 'inset 0 -2px 0 var(--chakra-colors-brand-primary)',
            backgroundColor: 'white',
            fontSize: 'x-small',
            fontWeight: 'bold',
            _selected: {
              color: 'brand.accent',
              boxShadow: 'inset 0 -3px 0 var(--chakra-colors-brand-accent)',
            },
            _disabled: {
              color: 'gray.300',
              boxShadow: 'inset 0 -2px 0 var(--chakra-colors-gray-300)',
            },
          },
        }),
        footerNav: (props: StyleFunctionProps) => ({
          ...theme.components.Tabs.variants?.line(props).tab,
          tab: {
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            boxShadow: 'inset 0 2px 0 var(--chakra-colors-brand-primary)',
            backgroundColor: 'white',
            fontSize: 'small',
            fontWeight: 'bold',
            _selected: {
              color: 'brand.accent',
              boxShadow: 'inset 0 2px 0 var(--chakra-colors-brand-accent)',
            },
            _disabled: {
              color: 'gray.300',
              boxShadow: 'inset 0 2px 0 var(--chakra-colors-gray-300)',
            },
          },
        }),
      },
    },
  },
});
