import { useContext } from 'react';
import { useRoutes } from 'react-router-dom';

import { AuthenticateContext } from '@/contexts/AuthProvider';

import { protectedRoutes } from './protected';
import { productionPublicRoutes, publicRoutes } from './public';

export const AppRoutes = () => {
  const authenticate = useContext(AuthenticateContext);
  const routes = authenticate.isLoggedIn ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};

// リリースするまでのルート
export const InDevAppRoutes = () => {
  const homeElement = useRoutes([...productionPublicRoutes]);
  return <>{homeElement}</>;
};
