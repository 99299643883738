import './wdyr';

import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import { App } from './App';
import { configureTracking } from './config/config';
import { reportWebVitals } from './reportWebVitals';

// Initialize GTM
if (configureTracking().googleTagManagerCode !== '') {
  TagManager.initialize({
    gtmId: configureTracking().googleTagManagerCode,
  });
}

const container = document.getElementById('root');

// See:https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
