import { Navigate } from 'react-router';

import { urls } from '@/urls';
import { lazyImport } from '@/utils/lazyImport';

const { HomeContainer } = lazyImport(() => import('@/features/misc'), 'HomeContainer');
const { LoginRoutes } = lazyImport(() => import('@/features/login'), 'LoginRoutes');

export const publicRoutes = [
  { path: '', element: <HomeContainer /> },
  { path: '/login', element: <LoginRoutes /> },
  { path: '*', element: <Navigate to={urls.login} /> },
];

export const productionPublicRoutes = [
  { path: '', element: <HomeContainer /> },
  { path: '*', element: <Navigate to={urls.root} /> },
];
